import React from 'react'
import Loader from '../../assets/images/loading.gif'

export const Loading = () => {
  return (
    <div className="spinner">
      <img src={Loader} width={'200px'} alt="loading" />
    </div>
  )
}
