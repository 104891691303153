import React, { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Loading } from '../Loading/Loading'
import { API_PATHS, instance, setToken } from '../../services/index'
import axios from 'axios'
import { globalConfig } from '../../config'

export const AuthContext = createContext({ userMetadata: null })

export const Auth = props => {
  const [userMetadata, setUserMetaData] = useState(null)
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { user, getAccessTokenSilently, logout } = useAuth0()
	
	console.log('u - ' + JSON.stringify(user));
	
  const getUserMetadata = async () => {
    try {
      //setIsLoading(true)
      const baseURL = 'https://origotelehealth.auth0.com/api/v2/'
      const userDetailsByIdUrl = `${baseURL}users/${user.sub}`
		
		console.log('userdetails - ' + userDetailsByIdUrl);
		
      const accessToken = await getAccessTokenSilently({
        audience: baseURL,
        scope: 'read:current_user',
      })
		console.log('token - ' + accessToken);
      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const { user_metadata: userMeta } = await metadataResponse.json()
      console.log('meta->' +JSON.stringify(userMeta))
      if (userMeta) {
        setToken(`${userMeta.apiKey}:${userMeta.userId}`);
        setUserMetaData(userMeta);
        setHasActiveSubscription(true);
        
        //useContext(userMeta);
      }
	
      //setIsLoading(true)
      
      //console.log('props - ' + JSON.stringify(props.children));
      
    } catch (error) {
      setIsLoading(false)
    }
      //console.log('props - ' + JSON.stringify(props.children));

  }

//   const retrieveEuphoriStripeCustomerDetails = async () => {
//     if (!userMetadata) {
//       return
//     }
// 
//     if (parseInt(userMetadata?.membershipType) === 3001) {
//       setHasActiveSubscription(true)
//       return
//     }
// 
//     setIsLoading(true)
//     try {
//       const customerId = userMetadata?.stripe?.id
//       const {
//         data,
//       } = await instance.post(
//         `${API_PATHS.mainPath}/subscriptions/euphoria_retrieve_stripe_customer`,
//         { customerId }
//       )
// 
//       console.log({ data })
//       // Has an active subscription
//       if (data.stripeCustomerData.subscriptions?.data[0].status === 'active') {
//         setHasActiveSubscription(true)
//       }
//       setIsLoading(false)
//     } catch (error) {
//       console.log({ error })
//       setIsLoading(false)
//     }
//   }

  useEffect(() => {
    getUserMetadata()
  }, [user])

//   useEffect(() => {
//     retrieveEuphoriStripeCustomerDetails()
//   }, [userMetadata])

  if (isLoading) {
    return <Loading />
  }

//   if (!userMetadata) {
//     return <Registration user={user} />
//   }

//         : (
//          <Pricing user={userMetadata} logout={logout} />
//        )
//    {
//    	console.log('um.'+userMetadata)
//    }
// 
	console.log(typeof props.children);
  return (
    <>
      {
      (hasActiveSubscription) ? (
        <AuthContext.Provider value={userMetadata}>
          {props.children}
        </AuthContext.Provider>
      )  : (<AuthContext.Provider value={userMetadata}>
      		 {props.children}
      		</AuthContext.Provider>
      		)
       }
    </>
  )
}
