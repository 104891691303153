import React from 'react'
import { MenuItem, TextField, Typography } from '@mui/material'
import { InputAdornment } from '@material-ui/core'
import PersonIcon from '@mui/icons-material/Person'
import HomeIcon from '@mui/icons-material/Home'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import MedicationIcon from '@mui/icons-material/Medication'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import { useFormikContext } from 'formik'
import { InputWrapper } from '../Form.styles'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { usa_regions, providerTypes } from '../Form-data/index'

const theme = createTheme({
  palette: {
    euphoria: {
      main: '#25be77',
      contrastText: '#fff',
    },
  },
})

const ProviderDetails = ({ nextStep }) => {
  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    validateForm,
    setTouched,
    values,
    touched,
    errors,
  } = useFormikContext()

  const handleNextStep = async () => {
    setTouched({
      firstName: true,
      middleName: true,
      lastName: true,
      mobilePhone: true,
      address: true,
      address2: true,
      state: true,
      country: true,
      postalCode: true,
      dateOfBirth: true,
      providerType: true,
    })

    let data = await validateForm()

    let isValid = Object.keys(data).length === 0

    console.log(data)

    if (isValid) {
      handleSubmit()

      return
    } else {
      return
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Typography variant="h5" component="div" gutterBottom>
          Personal details
        </Typography>
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.firstName && touched.firstName
                ? errors.firstName
                : 'Enter your first name'
            }
            variant="outlined"
            margin="normal"
            value={values.firstName}
            onChange={handleChange('firstName')}
            error={errors?.firstName && touched.firstName ? true : false}
            onBlur={() => setFieldTouched('firstName')}
            fullWidth
            placeholder="Enter your first name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.middleName && touched.middleName
                ? errors.middleName
                : 'Enter your middle name'
            }
            variant="outlined"
            margin="normal"
            value={values.middleName}
            onChange={handleChange('middleName')}
            error={errors?.middleName && touched.middleName ? true : false}
            onBlur={() => setFieldTouched('middleName')}
            fullWidth
            placeholder="Enter your middle name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.lastName && touched.lastName
                ? errors.lastName
                : 'Enter your last name'
            }
            variant="outlined"
            margin="normal"
            value={values.lastName}
            onChange={handleChange('lastName')}
            fullWidth
            error={errors?.lastName && touched.lastName ? true : false}
            onBlur={() => setFieldTouched('lastName')}
            placeholder="Enter your last name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.mobilePhone && touched.mobilePhone
                ? errors.mobilePhone
                : 'Enter your phone number'
            }
            variant="outlined"
            margin="normal"
            value={values.mobilePhone}
            onChange={handleChange('mobilePhone')}
            error={errors?.mobilePhone && touched.mobilePhone ? true : false}
            onBlur={() => setFieldTouched('mobilePhone')}
            fullWidth
            type="tel"
            required
            placeholder="Enter your phone number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            select
            label={
              errors.country && touched.country
                ? errors.country
                : 'Select your country'
            }
            error={errors?.country && touched.country ? true : false}
            helperText={'Select your country'}
            variant="outlined"
            margin="normal"
            value={values.country}
            onChange={handleChange('country')}
            onBlur={() => setFieldTouched('country')}
            placeholder="Enter your country"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={'US'}>United States of America</MenuItem>
          </TextField>

          <TextField
            id="outlined-basic"
            className="Input-div"
            select
            label={
              errors.state && touched.state ? errors.state : 'Select your state'
            }
            helperText={'Select your state'}
            variant="outlined"
            margin="normal"
            value={values.state}
            onChange={handleChange('state')}
            error={errors?.state && touched.state ? true : false}
            onBlur={() => setFieldTouched('state')}
            fullWidth
            placeholder="Enter your state"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
          >
            {usa_regions.map(el => {
              return (
                <MenuItem key={el.FIPS_CODE} value={el.FIPS_CODE}>
                  {el.REGION_NAME}
                </MenuItem>
              )
            })}
          </TextField>
        </InputWrapper>

        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.address && touched.address
                ? errors.address
                : 'Enter your address'
            }
            variant="outlined"
            margin="normal"
            value={values.address}
            onChange={handleChange('address')}
            error={errors?.address && touched.address ? true : false}
            onBlur={() => setFieldTouched('address')}
            fullWidth
            placeholder="Enter your address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.address2 && touched.address2
                ? errors.address2
                : 'Enter your address2'
            }
            variant="outlined"
            margin="normal"
            value={values.address2}
            onChange={handleChange('address2')}
            error={errors?.address2 && touched.address2 ? true : false}
            onBlur={() => setFieldTouched('address2')}
            placeholder="Enter your second address"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        <InputWrapper>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableFuture
              fullWidth
              id="date-picker-dialog"
              className="Input-div"
              openTo="year"
              views={['year', 'month', 'date']}
              label={
                errors.dateOfBirth && touched.dateOfBirth
                  ? errors.dateOfBirth
                  : 'Enter your date of birth'
              }
              helperText={null}
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
              onBlur={() => setFieldTouched('dateOfBirth')}
              error={errors?.dateOfBirth && touched.dateOfBirth ? true : false}
              onChange={date => {
                setFieldTouched('dateOfBirth')
                setFieldValue('dateOfBirth', date)
              }}
              value={values.dateOfBirth}
            />
          </MuiPickersUtilsProvider>

          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.country && touched.postalCode
                ? errors.postalCode
                : 'Enter your postal code'
            }
            variant="outlined"
            margin="normal"
            value={values.postalCode}
            onChange={handleChange('postalCode')}
            error={errors?.postalCode && touched.postalCode ? true : false}
            onBlur={() => setFieldTouched('postalCode')}
            placeholder="Enter your postal code"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MarkunreadMailboxIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            select
            fullWidth
            id="outlined-select"
            className="Input-div"
            label={
              errors.providerType && touched.providerType
                ? errors.providerType
                : 'Select your providerType'
            }
            error={errors?.providerType && touched.providerType ? true : false}
            helperText={'Select your providerType'}
            placeholder='="What is your speciality'
            value={values.providerType}
            onChange={handleChange('providerType')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MedicationIcon />
                </InputAdornment>
              ),
            }}
          >
            {providerTypes.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </InputWrapper>
        <div>
          <Button
            color="euphoria"
            size="large"
            variant="contained"
            onClick={async () => {
              handleNextStep()
            }}
          >
            Next
          </Button>
        </div>
      </ThemeProvider>
    </>
  )
}

export default ProviderDetails
