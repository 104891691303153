import styled from 'styled-components'

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Input-div {
    margin: 0 20px 20px 0;
  }
`

export const RadioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: ${props => (props.error ? '3px solid #ff0000;' : '1px solid #000;')};
  margin: 10px 0;
  padding: 10px 10px;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`
