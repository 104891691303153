import { Button } from '@mui/material'
import React from 'react'
//import {Image, StyleSheet} from 'react-native'
import { SubTitle, TabsContainer, Title } from './Registration.styles'
import LogoImage from '../../assets/images/OrigoHealthLogo2.png'

const RegistrationTabs = ({ activeTab, setActiveTab }) => {
  return (
    <TabsContainer>
    	<img
    		src={LogoImage} 
    		style={{height: '100%', width: '100%',}}
    	/>	
      {/* <SubTitle color="black">Sign up as a patient or provider</SubTitle> */}

      <div className="tabs-wrapper">
        {/* <Button
          color="euphoria"
          size="large"
          disableElevation
          variant={activeTab === 'Patient' ? 'contained' : 'outlined'}
          onClick={() => {
            setActiveTab('Patient')
          }}
        >
          Patient
        </Button> */}
        {/* &nbsp; &nbsp; */}
        {/* <Button
          color="euphoria"
          size="large"
          variant={activeTab === 'Provider' ? 'contained' : 'outlined'}
          disableElevation
          onClick={() => {
            setActiveTab('Provider')
          }}
        >
          Provider
        </Button> */}
      </div>
    </TabsContainer>
  )
}

export default RegistrationTabs
