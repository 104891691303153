import React, { useState } from 'react'
import { useFormik, Formik } from 'formik'
import UserDetails from './UserDetails'
import HealthDetails from './HealthDetails'
import { FormWrapper } from '../Form.styles'
import { validationSchema } from './validationSchema'
import Box from '@mui/material/Box'
import { Stepper } from 'react-form-stepper'
import { globalConfig } from '../../../../config'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import { Typography } from '@mui/material'
import { API_PATHS, instance } from '../../../../services'

const Signup = ({ loginWithRedirect }) => {
  const [step, setStep] = useState(0)
  const { addToast } = useToasts()

  const steps = ['Personal', 'Health', 'Payment']

  const onSubmitRegistration = async values => {
    try {
      const response = await instance.post(
        `${API_PATHS.mainPath}/user-reg-service/register-subscriber/origotelehealth`,
        {
          ...values,
          cloudName: 'origotelehealth',
        }
      )

      addToast('Registration complete', {
        autoDismiss: true,
        appearance: 'success',
      })
      return loginWithRedirect();
    } catch (error) {
      if (error?.response?.data?.errors?.length > 0) {
        error.response.data.errors.forEach(el => {
          addToast(el.msg, {
            autoDismiss: true,
            appearance: 'error',
          })
        })
      } else {
        addToast(error?.response?.data?.message || error?.message, {
          autoDismiss: true,
          appearance: 'error',
        })
      }
    }
  }

  const prevStep = () => {
    if (step === 0) {
      return
    } else {
      setStep(prev => prev - 1)
    }
  }

  const nextStep = () => {
    if (step === step.length - 1) {
      return
    } else {
      setStep(prev => prev + 1)
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
          mobilePhone: '',
          email: '',
          password: '',
          address: '',
          address2: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          postalCode: '',
          dateOfBirth: '',
          socialConnection: '',
          firstStepComplete: false,
          emergencyContactName: '',
          emergencyContactPhone: '',
          question_1: null,
          question_2: null,
          question_3: null,
          question_4: null,
          question_5: null,
          question_6: null,
          question_7: null,
          question_8: null,
          question_9: null,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSubmitRegistration(values)
        }}
      >
        <>
          <FormWrapper>
            <Box sx={{ width: '100%' }}>
              {/* <Typography variant="h4" component="h5" gutterBottom>
                Patient Sign Up
              </Typography>{' '} */}
              {/* <Stepper
                styleConfig={{
                  activeBgColor: '#25be77',
                  completedBgColor: '#25be77',
                  labelFontSize: '1.25rem',
                }}
                steps={steps.map(el => {
                  return { label: el }
                })}
                activeStep={step}
              /> */}
            </Box>
            {step === 0 && <UserDetails nextStep={nextStep} />}
            {/* {step === 1 && (
              <HealthDetails
                nextStep={nextStep}
                prevStep={prevStep}
                user={user}
              />
            )} */}
          </FormWrapper>
        </>
      </Formik>
    </>
  )
}

export default Signup
