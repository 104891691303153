import React from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { InputAdornment } from '@material-ui/core'
import PersonIcon from '@mui/icons-material/Person'

import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { useToasts } from 'react-toast-notifications'

import { useFormikContext } from 'formik'
import { ButtonContainer, InputWrapper, RadioWrapper } from '../Form.styles'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { answers, questions } from '../Form-data/index'

const theme = createTheme({
  palette: {
    euphoria: {
      main: '#25be77',
      contrastText: '#fff',
    },
  },
})

const HealthDetails = ({ prevStep }) => {
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    validateForm,
    setTouched,
    values,
    touched,
    errors,
  } = useFormikContext()

  const handleNextStep = async () => {
    setTouched({
      emergencyContactName: true,
      emergencyContactPhone: true,
      question_1: true,
      question_2: true,
      question_3: true,
      question_4: true,
      question_5: true,
      question_6: true,
      question_7: true,
      question_8: true,
      question_9: true,
    })
    let data = await validateForm(values)

    let isValid = Object.keys(data).length === 0

    if (isValid) {
      handleSubmit()
    } else {
      return
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Typography variant="h5" component="div" gutterBottom>
          Emergency contact details
        </Typography>
        &nbsp;
        <InputWrapper>
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.emergencyContactName && touched.emergencyContactName
                ? errors.emergencyContactName
                : 'Enter name of emergency contact'
            }
            variant="outlined"
            margin="normal"
            value={values.emergencyContactName}
            onChange={handleChange('emergencyContactName')}
            error={
              errors?.emergencyContactName && touched.emergencyContactName
                ? true
                : false
            }
            onBlur={() => setFieldTouched('emergencyContactName')}
            fullWidth
            placeholder="Enter name of emergency contact"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="outlined-basic"
            className="Input-div"
            label={
              errors.emergencyContactPhone && touched.emergencyContactPhone
                ? errors.emergencyContactPhone
                : 'Enter phone number of emergency contact'
            }
            variant="outlined"
            margin="normal"
            value={values.emergencyContactPhone}
            onChange={handleChange('emergencyContactPhone')}
            error={
              errors?.emergencyContactPhone && touched.emergencyContactPhone
                ? true
                : false
            }
            onBlur={() => setFieldTouched('emergencyContactPhone')}
            fullWidth
            type="tel"
            required
            placeholder="Enter phone number of emergency contact"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneIcon />
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>
        <Typography variant="h5" component="div" gutterBottom>
          Health questionnaire
        </Typography>
        <Typography variant="h6" component="div" gutterBottom>
          Over The past 2 weeks How often have you been bothered by any of the
          following problems ?
        </Typography>
        {questions.map(question => {
          return (
            <RadioWrapper
              key={question.value}
              error={errors[question.value] && touched[question.value]}
            >
              <span
                style={{
                  width: '30%',
                }}
                id="demo-row-radio-buttons-group-label"
              >
                {question.label}
              </span>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleChange(question.value)}
              >
                {answers.map(ans => (
                  <FormControlLabel
                    key={ans.value}
                    value={ans.value}
                    control={<Radio size="small" color="error" />}
                    label={ans.label}
                  />
                ))}
              </RadioGroup>
            </RadioWrapper>
          )
        })}
        <ButtonContainer>
          <Button
            color="euphoria"
            size="large"
            variant="contained"
            onClick={() => {
              setFieldValue('firstStepComplete', false)
              prevStep()
            }}
          >
            Prev
          </Button>
          &nbsp; &nbsp; &nbsp;
          <Button
            color="euphoria"
            size="large"
            variant="contained"
            onClick={async () => {
              handleNextStep()
            }}
          >
            Submit
          </Button>
        </ButtonContainer>
      </ThemeProvider>
    </>
  )
}

export default HealthDetails
