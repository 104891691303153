import * as yup from 'yup'

import { differenceInYears } from 'date-fns'

const REQUIRED_MESSAGE = 'Field required'
const PHONE_MESSAGE = 'Please enter a number with a valid country code'
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

export const validationSchema = yup.object({
  firstName: yup.string().required(REQUIRED_MESSAGE),
  middleName: yup.string(),
  lastName: yup.string().required(REQUIRED_MESSAGE),
  mobilePhone: yup.string().required(PHONE_MESSAGE),
  email: yup.string().email().required(REQUIRED_MESSAGE),
  password: yup.string().required(REQUIRED_MESSAGE),
  dateOfBirth: yup
    .date()
    .test(
      'birthDate should be greater than 18',
      'You should be at least 18 years old',
      value => {
        return differenceInYears(new Date(), value) >= 18
      }
    )
    .required(REQUIRED_MESSAGE),
  address: yup.string().required(REQUIRED_MESSAGE),
  country: yup.string().required(REQUIRED_MESSAGE),
  state: yup.string().required(REQUIRED_MESSAGE),
  postalCode: yup.string().required(REQUIRED_MESSAGE),
  firstStepComplete: yup.boolean(),
  emergencyContactName: yup.string().when('firstStepComplete', {
    is: true,
    then: yup.string().required(REQUIRED_MESSAGE),
  }),
  emergencyContactPhone: yup.string().when('firstStepComplete', {
    is: true,
    then: yup.string().required(PHONE_MESSAGE),
  }),
  question_1: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_2: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_3: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_4: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_5: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_6: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_7: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_8: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  question_9: yup
    .string()
    .nullable()
    .when('firstStepComplete', {
      is: true,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
})
